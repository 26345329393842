<template>
    <div>
        <form>
            <input type="text" v-modal="firstname" />
            <input type="text" v-modal="lastname" />
        </form>
    </div>
</template>
<script setup>  

</script>
<style>
    
</style>